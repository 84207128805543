import axios from "axios";
import { BASE_URL } from "./Base";
import router from "@/router";
const api = axios.create({ baseURL: `${BASE_URL}/api` });
const getAccessToken = () => localStorage.getItem("token");
const getRefreshToken = () =>
  JSON.parse(localStorage.getItem("user"))?.refreshToken?.replace(
    "Bearer ",
    ""
  );
const addAuthorizationHeader = (config) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers = config.headers || {};
    config.headers["Authorization"] = `${accessToken}`;
  }
  return config;
};
const refreshAccessToken = async () => {
  const refreshToken = getRefreshToken();
  if (refreshToken) {
    try {
      const response = await axios.post(`${BASE_URL}/api/refresh-token`, {
        refreshToken,
      });
      localStorage.setItem("token", response.data.result.token);
      return response.data.result.token;
    } catch (error) {
      console.error("Token refresh failed", error);
      router.push({ path: "/sign-in" });
      throw error;
    }
  }
  router.push({ path: "/sign-in" });
};
api.interceptors.request.use(addAuthorizationHeader);
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const accessToken = getAccessToken();
    if (error.response?.status === 401 && accessToken) {
      const newAccessToken = await refreshAccessToken();
      if (newAccessToken) {
        error.config.headers["Authorization"] = `${newAccessToken}`;
        return axios(error.config); // Retry the request with new token
      }
    }
    return Promise.reject(error);
  }
);

export { api };
